
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        

























































































#contact-section {
  background: #ffffff;
  // background: $intro-back;
  min-height: 70vh;
  display: flex;
  flex-wrap: wrap;
  gap: 50px 60px;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  margin-top: calc(#{$mob-nav-height} + #{$mob-nav-padding-y} * 2);
  position: relative;

  > img {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.5;
  }

  @media screen and (min-width: 768px) {
    padding: 40px 20px;
    margin-top: 0;
    padding-top: calc(#{$full-nav-height});
    // margin-top: calc(#{$full-nav-height});
  }

  .left {
    $max-wid: 400px;
    max-width: $max-wid;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px 10px;

    > .super-title {
      color: $accent-color;
      font-weight: 500;
    }

    > .title {
      margin: 0 0 30px 0;
      font-weight: bold;
    }

    > .title-desc {
      color: $light-text;
      font-family: 'Mulish';
      line-height: 34px;
    }

    input,
    textarea {
      max-width: $max-wid;
      width: 100%;

      &.error {
        border: 1px solid $error-color;
      }
    }

    textarea {
      height: 120px;
    }

    @media screen and (min-width: 768px) {
      margin: 0 100px 0 0;
    }

    button {
      margin: 50px auto 0;
      width: 208px;

      &.alt {
        border: 2px solid;
        border-color: $accent-color;
      }

      @media screen and(min-width: 768px) {
        margin: 50px 0 0 0;
      }
    }
  }

  .right {
    img {
      width: 100%;
    }
  }
}
