
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        

































































































.other-page {
  #full-nav {
    background: rgba(77, 77, 77, 0.198);
  }
}
