
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        













































#mob-footer {
  @include footer-bar;
  $footer-back: #404757;
  $text-color: white;
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: $footer-back;
  min-height: 200px;
  padding: 20px;
  row-gap: 40px;
  column-gap: 30px;
  color: $text-color;
  // max-width: 800px;

  @media screen and (min-width: 768px) {
    row-gap: 80px;
  }

  .left {
    .title {
      text-align: center;
    }
  }

  .right {
    display: flex;
    width: 100%;
    justify-content: space-between;

    ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      // flex-wrap: nowrap;

      li {
        margin-left: 10px;
        cursor: pointer;

        &:hover {
          color: darken($color: white, $amount: 10);
        }
      }

      a {
        text-decoration: none;
        color: inherit;
      }
    }

    .external-links {
      margin-left: 30px;
      display: flex;
      gap: 5px;
      align-items: center;

      > .fab {
        margin-right: 10px;
        cursor: pointer;

        &:hover {
          color: darken($color: $text-color, $amount: 20);
        }
      }
    }
  }
}
