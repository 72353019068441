
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        


















#try-section {
  background: linear-gradient(89.56deg, #ee7b56 -1.9%, #f1756d 102.96%);
  min-height: 30vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  color: white;

  @media screen and (min-width: 768px) {
    padding: 40px 20px;
  }

  .title {
    text-align: center;
  }

  .title-desc {
    margin-bottom: 85px;
    text-align: center;
    max-width: 900px;
  }

  > * {
    margin-top: 10px;
  }

  button {
    width: 208px;
  }
}
