
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        























































































#home-page {
  height: 100%;
  background: $intro-back;
  color: $homepage-color;

  // header {}
  // footer {}
}
