
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        













































#full-footer {
  $footer-back: #404757;
  $text-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: $footer-back;
  min-height: 160px;
  padding: 20px;
  // row-gap: 200px;
  column-gap: 20%;
  color: $text-color;

  @media screen and (min-width: 768px) {
    row-gap: 80px;
  }

  .left {
    .title {
      user-select: none;
    }
  }

  .right {
    display: flex;
    justify-content: space-between;

    ul {
      list-style-type: none;
      display: flex;
      margin: 0;
      padding: 0;
      // flex-wrap: nowrap;

      li {
        margin-left: 10px;
        cursor: pointer;

        &:hover {
          color: darken($color: white, $amount: 10);
        }
      }

      a {
        text-decoration: none;
        color: inherit;
      }
    }

    .external-links {
      margin-left: 30px;
      display: flex;
      gap: 5px;
      align-items: center;

      > .fab {
        margin-right: 10px;
        cursor: pointer;

        &:hover {
          color: darken($color: $text-color, $amount: 20);
        }
      }
    }
  }
}
