
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        



































































































#mob-nav {
  @include mob-nav-bar;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
