
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        




















































































































.multiselect-view {
  $size: 80px;
  max-width: $size;
  width: $size;
  background: transparent;
  box-shadow: unset;

  ::v-deep input {
    background: transparent;
  }
}
